/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Candidate_jsonld_connection_employer } from './Candidate_jsonld_connection_employer';
export type Connection_jsonld_connection_employer = {
    readonly '@context': (string | Record<string, any>);
    readonly '@id': string;
    readonly '@type': string;
    readonly uuid: string;
    status: Connection_jsonld_connection_employer.status;
    shareStatus: Connection_jsonld_connection_employer.shareStatus;
    updatedAt: string;
    search: string | null;
    candidate: Candidate_jsonld_connection_employer;
};
export namespace Connection_jsonld_connection_employer {
    export enum status {
        PROSPECT = 'prospect',
        CONCEPT = 'concept',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
        CANCELED = 'canceled',
    }
    export enum shareStatus {
        NONE = 'none',
        REQUESTED = 'requested',
        ACCEPTED = 'accepted',
        DECLINED = 'declined',
    }
}

