import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  AuthenticationService,
  CandidateEmailChange_candidate_email_change_request_create,
  CandidateRegistration,
  CandidateService,
  Candidate_candidate_list,
  Candidate_candidate_update_self,
  ChangeEmailConfirmation_candidate_email_change_confirmation_create,
  ChatService,
  ConnectionService,
  Connection_MarkAsReadRequest,
  Connection_candidate_list,
  Credentials,
  EducationLevelService,
  EmployerActivation,
  EmployerRegistration,
  EmployerService,
  Employer_employer_admin_update,
  Employer_employer_update_self,
  Feedback,
  FeedbackService,
  GenderService,
  ImpersonationService,
  JWTAuth,
  JWTRefresh,
  JWTResponse,
  MessageTemplateService,
  MessageTemplate_message_template_create,
  MessageTemplate_message_template_update,
  OpenAPI,
  PasswordReset,
  PasswordResetRequest,
  QuestionService,
  SearchService,
  Search_search_create,
  Search_search_update,
  SectorService,
  Sector_sector_view,
} from 'generated/api';
import { useMemo } from 'react';
import { getLocationLatlong } from 'utils/getLocationLatlong';
import { GeoCodeingRegion, GeoCodingLatLong } from 'utils/interfaces';
import { getAdminTokens, getTokens } from './tokens';

const JWT_ENDPOINT_REGEX = /^\/(employer|candidate|admin)api\/jwt\//;
const ADMIN_ENDPOINT_REGEX = /^\/adminapi\//;

OpenAPI.BASE = process.env.NEXT_PUBLIC_API_URL || '';
OpenAPI.TOKEN = async (requestOptions) => {
  if (JWT_ENDPOINT_REGEX.test(requestOptions.url)) {
    return '';
  }

  if (ADMIN_ENDPOINT_REGEX.test(requestOptions.url)) {
    const adminTokens = getAdminTokens();
    return adminTokens?.accessToken || '';
  }

  return getTokens()?.accessToken || '';
};

export const CANDIDATE_USE_ME_QUERY_KEY = 'useCandidateMe';
export const EMPLOYER_USE_ME_QUERY_KEY = 'useEmployerMe';
export const CANDIDATE_DELETE_MYSELF_QUERY_KEY = 'useDeleteCandidate';
export const CANDIDATE_DELETE_MYSELF_REASON_QUERY_KEY = 'useDeleteCandidateReason';
const FIFTY_MINUTES = 1000 * 60 * 50;

export const getSlugsForSectors = async () => {
  const sectors = await SectorService.listSectors({ itemsPerPage: Number.MAX_SAFE_INTEGER });

  return sectors
    .filter((sector): sector is Required<Sector_sector_view> => !!sector.name)
    .map(({ name }) => name.toLowerCase());
};

export const registerCandidate = (requestBody: CandidateRegistration) =>
  CandidateService.candidateRegister({ requestBody });
export const activateCandidate = (code: string) => CandidateService.candidateActivate({ requestBody: { code } });
export const candidateLogin = (email: string) =>
  AuthenticationService.candidateSendLoginLink({ requestBody: { email } });
export const candidateLoginCheck = ({ user, expires, hash }: { user: string; expires: string; hash: string }) =>
  AuthenticationService.apiCandidatesLoginCheck({ user, expires, hash });
export const candidateRefresh = (requestBody: JWTRefresh) => AuthenticationService.jwtRefresh1({ requestBody });

export const candidateChangeEmail = (requestBody: CandidateEmailChange_candidate_email_change_request_create) =>
  CandidateService.candidateChangeEmail({ requestBody });

export const useCandidateUpdateProfile = () =>
  useMutation({
    mutationFn: ({ uuid, requestBody }: { uuid: string; requestBody: Candidate_candidate_update_self }) =>
      CandidateService.candidateUpdateMyself({ uuid, requestBody }),
  });

export const candidateConfirmChangeEmail = (
  requestBody: ChangeEmailConfirmation_candidate_email_change_confirmation_create
) => CandidateService.candidateChangeEmailConfirm({ requestBody });

export const useCandidateMe = (properties?: { enabled?: boolean }) =>
  useQuery({
    queryKey: [CANDIDATE_USE_ME_QUERY_KEY],
    queryFn: () => CandidateService.candidateMe(),
    enabled: properties?.enabled === undefined ? true : properties?.enabled,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: FIFTY_MINUTES,
  });

export const adminLogin = async (data: JWTAuth): Promise<JWTResponse> =>
  AuthenticationService.jwtToken({ requestBody: data });

export const adminRefresh = async (refresh_token: string) =>
  AuthenticationService.jwtRefresh({ requestBody: { refresh_token } });

const ADMIN_IMPERSONATE_KEY = 'useAdminImpersonate';

export const useAdminImpersonate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (email: string) => ImpersonationService.adminImpersonateEmployer({ requestBody: { email } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_IMPERSONATE_KEY] });
    },
  });
};

const ADMIN_IMPERSONATE_CANDITATE_KEY = 'useAdminImpersonateCanditate';

export const useAdminImpersonateCanditate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (email: string) => ImpersonationService.adminImpersonateCandidate({ requestBody: { email } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_IMPERSONATE_CANDITATE_KEY] });
    },
  });
};

export const useAdminUpdateEmployerStatus = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      uuid,
      accountStatus,
    }: {
      uuid: string;
      accountStatus: Employer_employer_admin_update.accountStatus;
    }) => EmployerService.adminUpdateEmployer({ uuid, requestBody: { accountStatus } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [ADMIN_USE_EMPLOYER_QUERY_KEY] });
    },
  });
};

const ADMIN_USE_EMPLOYERS_QUERY_KEY = 'useAdminEmployers';
const ADMIN_USE_CANDITATES_QUERY_KEY = 'useAdminCandidates';

export const useAdminGetEmployers = ({ enabled, email }: { enabled: boolean; email?: string }) =>
  useQuery({
    queryKey: [ADMIN_USE_EMPLOYERS_QUERY_KEY],
    queryFn: () => EmployerService.adminListEmployers({ email, itemsPerPage: Number.MAX_SAFE_INTEGER }),
    enabled,
  });

export const useAdminGetCandidates = ({ enabled, email }: { enabled: boolean; email?: string }) =>
  useQuery({
    queryKey: [ADMIN_USE_CANDITATES_QUERY_KEY],
    queryFn: () => CandidateService.adminListCandidates({ email, itemsPerPage: Number.MAX_SAFE_INTEGER }),
    enabled,
  });

const ADMIN_USE_EMPLOYER_QUERY_KEY = 'useAdminEmployer';

export const useAdminGetEmployer = ({ uuid }: { uuid: string }) =>
  useQuery({
    queryKey: [ADMIN_USE_EMPLOYER_QUERY_KEY, uuid],
    queryFn: () => EmployerService.adminViewEmployer({ uuid }),
    enabled: !!uuid,
  });

export const useAdminViewCandidate = ({ uuid }: { uuid: string }) =>
  useQuery({
    queryKey: ['useAdminViewCandidate', uuid],
    queryFn: () => CandidateService.adminViewCandidate({ uuid }),
    enabled: !!uuid,
  });

export const employerLogin = (requestBody: JWTAuth) => AuthenticationService.jwtToken1({ requestBody });
export const employerRefresh = (requestBody: JWTRefresh) => AuthenticationService.jwtRefresh2({ requestBody });
export const employerRequestPasswordReset = (requestBody: PasswordResetRequest) =>
  EmployerService.employerRequestPasswordReset({ requestBody });
export const employerResetPassword = (requestBody: PasswordReset) =>
  EmployerService.employerConfirmPasswordReset({ requestBody });

export const registerEmployer = (requestBody: Omit<EmployerRegistration, 'code' | 'createdAt'>) =>
  // createdAt and code are readonly strings so we ignore the ts error here
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  EmployerService.employerRegister({ requestBody });

export const activateEmployer = (requestBody: EmployerActivation) => EmployerService.employerActivate({ requestBody });

export const useUpdateEmployerProfile = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid, requestBody }: { uuid: string; requestBody: Employer_employer_update_self }) =>
      EmployerService.employerUpdateMyself({ uuid, requestBody }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYER_USE_ME_QUERY_KEY] });
    },
  });
};

export const updateEmployerCredentials = (requestBody: Credentials) =>
  EmployerService.employerUpdateCredentials({ requestBody });

export const useUpdateCompanyBanner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ file }: { file: string }) => EmployerService.employerUploadBanner({ requestBody: { file } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYER_USE_ME_QUERY_KEY] });
    },
  });
};

export const useUpdateCompanyLogo = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ file }: { file: string }) => EmployerService.employerUploadLogo({ requestBody: { file } }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYER_USE_ME_QUERY_KEY] });
    },
  });
};

export const useEmployerMe = ({ enabled }: { enabled: boolean }) =>
  useQuery({
    queryKey: [EMPLOYER_USE_ME_QUERY_KEY],
    queryFn: () => AuthenticationService.employerViewMyself(),
    enabled,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: FIFTY_MINUTES,
  });

export const useCandidateViewEmployer = ({ employerId, enabled }: { enabled: boolean; employerId: string }) =>
  useQuery({
    queryKey: [EMPLOYER_USE_ME_QUERY_KEY],
    queryFn: () => EmployerService.candidateViewEmployer({ uuid: employerId }),
    enabled,
  });

export const saveSearch = (requestBody: Search_search_create) => SearchService.employerCreateSearch({ requestBody });

export const useDeleteSearch = (onSuccess: () => void) =>
  useMutation({ mutationFn: ({ id }: { id: string }) => SearchService.employerDeleteSearch({ id }), onSuccess });

export const useSearches = () =>
  useQuery({
    queryKey: ['useSearches'],
    queryFn: () => SearchService.employerListSearches({ itemsPerPage: Number.MAX_SAFE_INTEGER }),
  });

const USESEARCH_QUERY_KEY = 'useSearch';

export const useSearch = (id: string | undefined) =>
  useQuery({
    queryKey: [USESEARCH_QUERY_KEY, { id }],
    queryFn: () => SearchService.employerViewSearch({ id: id ?? '' }),
  });

export const useUpdateSearch = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ id, requestBody }: { id: string; requestBody: Search_search_update }) =>
      SearchService.employerUpdateSearch({ id, requestBody }),
    onSuccess: (context) => {
      queryClient.invalidateQueries({ queryKey: [USESEARCH_QUERY_KEY, { id: context.id }] });
    },
  });
};

export const useEducationLevels = () =>
  useQuery({
    queryKey: ['useEducationLevels'],
    queryFn: () => EducationLevelService.listEducationLevels({ itemsPerPage: Number.MAX_SAFE_INTEGER }),
  });

export const useSectors = (enabled?: boolean) =>
  useQuery({
    queryKey: ['useSectors'],
    queryFn: () => SectorService.listSectors({ itemsPerPage: Number.MAX_SAFE_INTEGER }),
    enabled,
  });

export const useGenders = () =>
  useQuery({
    queryKey: ['useGenders'],
    queryFn: () => GenderService.listGenders({ itemsPerPage: Number.MAX_SAFE_INTEGER }),
  });

export const useCandidateDetails = (
  uuid: string | undefined,
  options?: {
    enabled?: boolean;
  }
) =>
  useQuery({
    queryKey: ['useCandidateDetails', { uuid }],
    queryFn: () => CandidateService.employerViewCandidate({ uuid: uuid ?? '' }),
    enabled: options?.enabled || !!uuid,
  });

const defaultFilterFunction = (candidates: Candidate_candidate_list[]) =>
  candidates.filter(
    (candidate) =>
      candidate.currentConnection?.status !== Connection_candidate_list.status.CANCELED &&
      candidate.currentConnection?.status !== Connection_candidate_list.status.DECLINED
  );

export const useCandidates = (
  parameters?: Parameters<typeof CandidateService.employerListCandidates>[0] | undefined,
  options?: {
    enabled?: boolean;
    filterFunction?: (unfiltered: Candidate_candidate_list[]) => Candidate_candidate_list[];
  }
) => {
  const query = useQuery({
    queryKey: ['useCandidates', parameters],
    queryFn: () =>
      CandidateService.employerListCandidates({
        ...parameters,
        itemsPerPage: 500,
      }),
    enabled: options?.enabled ?? true,
  });

  const data = useMemo(() => {
    if (!query.data) {
      return query.data;
    }
    const filterFunction = options?.filterFunction || defaultFilterFunction;
    return filterFunction(query.data);
  }, [options, query.data]);

  return { ...query, data };
};

const CONNECTIONS_QUERY_KEY = 'useConnections';

export const useConnections = (enabled?: boolean) =>
  useQuery({
    queryKey: [CONNECTIONS_QUERY_KEY],
    queryFn: () => ChatService.candidateListConnections({ itemsPerPage: Number.MAX_SAFE_INTEGER }),
    enabled,
  });
export const useUnreadMessagesCandidate = (enabled?: boolean) =>
  useQuery({
    queryKey: ['useUnreadMessagesCandidate'],
    queryFn: () => ChatService.candidateCountUnreadMessages(),
    enabled,
  });

export const markAsReadCandidate = (uuid: string, requestBody: Connection_MarkAsReadRequest) =>
  ConnectionService.candidateMarkConnectionAsRead({ uuid, requestBody });

export const useUnreadMessagesEmployer = (enabled?: boolean) =>
  useQuery({
    queryKey: ['useUnreadMessagesEmployer'],
    queryFn: () => ChatService.employerCountUnreadMessages(),
    enabled,
  });

export const markAsReadEmployer = (uuid: string, requestBody: Connection_MarkAsReadRequest) =>
  ConnectionService.employerMarkConnectionAsRead({ uuid, requestBody });

const EMPLOYER_CONNECTIONS_QUERY_KEY = 'useEmployerConnections';

export const useEmployerConnections = (enabled?: boolean) =>
  useQuery({
    queryKey: [EMPLOYER_CONNECTIONS_QUERY_KEY],
    queryFn: () => ChatService.employerListConnections({ itemsPerPage: Number.MAX_SAFE_INTEGER }),
    enabled,
  });

export const useConnection = ({ id, enabled }: { id: string; enabled?: boolean }) =>
  useQuery({
    queryKey: ['useConnection', id],
    queryFn: () => ChatService.candidateViewConnection({ uuid: id }),
    enabled: enabled && !!id,
  });

export const useEmployerConnection = ({ id, enabled = true }: { id: string; enabled?: boolean }) =>
  useQuery({
    queryKey: ['useEmployerConnection', id],
    queryFn: () => ChatService.employerViewConnection({ uuid: id }),
    enabled,
  });

export const useEmployerMessages = ({
  itemsPerPage,
  connectionId,
  polling,
  enabled = true,
}: {
  itemsPerPage: number;
  connectionId?: string;
  polling?: number;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: ['useEmployerChat', connectionId],
    queryFn: () => ChatService.employerListMessages({ itemsPerPage, connectionUuid: connectionId }),
    enabled,
    refetchInterval: polling,
  });

export const useMessages = ({
  itemsPerPage,
  connectionId,
  enabled,
  polling,
}: {
  itemsPerPage: number;
  connectionId?: string;
  enabled?: boolean;
  polling?: number;
}) =>
  useQuery({
    queryKey: ['useChat', connectionId],
    queryFn: () => ChatService.candidateListMessages({ itemsPerPage, connectionUuid: connectionId }),
    enabled,
    refetchInterval: polling,
  });

export const useCandidateSendMessage = () =>
  useMutation({
    mutationFn: ({ connection, contents }: { connection: string; contents: string }) =>
      ChatService.candidateCreateMessage({ requestBody: { connection, contents } }),
    retry: 1,
  });

export const useEmployerSendMessage = () =>
  useMutation({
    mutationFn: ({ connection, contents }: { connection: string; contents: string }) =>
      ChatService.employerCreateMessage({ requestBody: { connection, contents } }),
  });
export const requestConnection = (connectionId: string, message: string) =>
  ChatService.employerRequestChat({ uuid: connectionId, requestBody: { message } });

export const requestBatchConnection = (candidateUuids: string[], message: string, search: string) =>
  ConnectionService.employerRequestQualitySelectChat({ requestBody: { candidates: candidateUuids, message, search } });

export const acceptConnection = (connectionId: string) => ChatService.candidateAcceptConnection({ uuid: connectionId });

export const useDeclineConnection = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (connectionId: string) => ChatService.candidateDeclineConnection({ uuid: connectionId }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CONNECTIONS_QUERY_KEY] });
    },
  });
};

export const useEmployerCancelConnection = () =>
  useMutation({
    mutationFn: (connectionId: string) => ChatService.employerCancelConnection({ uuid: connectionId }),
  });

export const useCandidateCancelConnection = () =>
  useMutation({
    mutationFn: (connectionId: string) => ChatService.candidateCancelConnection({ uuid: connectionId }),
  });

export const requestDataSharing = (connectionId: string) =>
  ChatService.employerRequestDataSharing({ uuid: connectionId });

export const acceptDataSharing = (connectionId: string) =>
  ChatService.candidateAcceptDataSharing({ uuid: connectionId });

export const declineDataSharing = (connectionId: string) =>
  ChatService.candidateDeclineDataSharing({ uuid: connectionId });

const GOOGLE_MAPS_GEO_CODING_API_KEY = process.env.NEXT_PUBLIC_MAPS_GEO_CODING_API_KEY;

export const usePostalcodeCoordinates = ({ postalCode, enabled }: { postalCode?: string | null; enabled?: boolean }) =>
  useQuery({
    queryKey: ['usePostalcodeCoordinates', postalCode],
    queryFn: () => fetch(getPdokUrlForPostalCode(postalCode)).then((r) => r.json() as Promise<GeoCodingLatLong>),
    enabled,
    retry: false,
  });

export const getZipcodeFromLatLong = async ({ lat, lng }: { lat: string; lng: string }) => {
  const response = await fetch(
    `https://api.pdok.nl/bzk/locatieserver/search/v3_1/reverse?&wt=json&fl=id weergavenaam postcode&rows=1&lat=${lat}&lon=${lng}`
  );

  const json = await response.json();

  return json.response.docs[0].postcode;
};

export const getLatlongFromZipcode = async ({ postalCode }: { postalCode?: string }) => {
  if (!postalCode) return;
  const response = await fetch(getPdokUrlForPostalCode(postalCode));

  const json = await response.json();

  return getLocationLatlong(json);
};

export const useCandidateRegion = (latLong?: { lat: string | null; lng: string | null }) =>
  useQuery({
    queryKey: ['useCandidateRegion', latLong],
    queryFn: () =>
      fetch(
        `https://api.pdok.nl/bzk/locatieserver/search/v3_1/reverse?start=0&wt=json&fl=id weergavenaam&rows=1&lat=${latLong?.lat}&lon=${latLong?.lng}&type=gemeente`
      ).then((r) => r.json() as Promise<GeoCodeingRegion>),
    enabled: !!GOOGLE_MAPS_GEO_CODING_API_KEY && !!latLong,
    retry: false,
  });

export function getPdokUrlForPostalCode(postalCode?: string | null) {
  return `https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?q=${postalCode}&fl=centroide_ll postcode gemeentenaam&df=tekst&bq=type:postcode^1&start=0&rows=1&sort=score%20desc,sortering%20asc,weergavenaam%20asc&wt=json`;
}

export const useCandidateRegionFromPostalCode = (postalCode?: string | null) =>
  useQuery({
    queryKey: ['useCandidateRegionFromPostalCode', postalCode],
    queryFn: () => fetch(getPdokUrlForPostalCode(postalCode)).then((r) => r.json() as Promise<GeoCodeingRegion>),
    enabled: !!postalCode,
    retry: false,
  });

export const useDeleteCandidate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: () => CandidateService.candidateDeleteMyself(),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CANDIDATE_DELETE_MYSELF_QUERY_KEY] });
    },
  });
};

export const useReasonToDeleteCandidate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (feedback: Feedback) => FeedbackService.candidateGiveFeedback({ requestBody: feedback }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [CANDIDATE_DELETE_MYSELF_REASON_QUERY_KEY] });
    },
  });
};

const EMPLOYER_GET_MESSAGE_TEMPLATES_QUERY_KEY = 'useEmployerGetMessageTemplates';

export const useEmployerGetMessageTemplates = () =>
  useQuery({
    queryKey: [EMPLOYER_GET_MESSAGE_TEMPLATES_QUERY_KEY],
    queryFn: () => MessageTemplateService.employerListMessageTemplates({ itemsPerPage: 100 }),
  });

export const useEmployerCreateMessageTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: MessageTemplate_message_template_create) =>
      MessageTemplateService.employerCreateMessageTemplate({ requestBody: data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYER_GET_MESSAGE_TEMPLATES_QUERY_KEY] });
    },
  });
};

export const useEmployerDeleteMessageTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (uuid: string) => MessageTemplateService.employerDeleteMessageTemplate({ uuid }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYER_GET_MESSAGE_TEMPLATES_QUERY_KEY] });
    },
  });
};

export const useEmployerUpdateMessageTemplate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ uuid, requestBody }: { uuid: string; requestBody: MessageTemplate_message_template_update }) =>
      MessageTemplateService.employerUpdateMessageTemplate({ uuid, requestBody }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [EMPLOYER_GET_MESSAGE_TEMPLATES_QUERY_KEY] });
    },
  });
};

export const useQualitySelectQuestions = (search: string) =>
  useQuery({
    queryKey: ['useQualitySelectQuestions'],
    queryFn: () => QuestionService.qualitySelectListQuestions({ search }),
    enabled: !!search,
  });

export const useAddQualitySelectQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { question: string; search: string }) =>
      QuestionService.qualitySelectAddQuestion({ requestBody: data }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['useQualitySelectQuestions'] });
    },
  });
};

export const useDeleteQualitySelectQuestion = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (uuid: string) => QuestionService.qualitySelectDeleteQuestion({ uuid }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['useQualitySelectQuestions'] });
    },
  });
};

export const useMarkCandidateAsProspect = () =>
  useMutation({
    mutationFn: ({ connectionId }: { connectionId: string }) =>
      ChatService.employerMarkConnectionAsProspect({ uuid: connectionId }),
  });
